import { post, postJson } from "../util/http"
/**
 * 列表查询
 * @param  params 
 */
export const SelectList = params => post(`/stock/selectList`, params)

/**
 * 分页
 * @param  params 
 */
export const SelectPage = params => post(`/stock/selectPage`, params)

/**
 * 板块查询
 * @param  params 
 */
export const SelectListBoard = params => post(`/stock/board/selectList`, params)
/**
 * 添加机选
 * @param  params 
 */
export const AddMachinePool = params => postJson(`/stock/addMachinePool`, params)
