  <!-- 分类标签 -->
  <template>
    <div class="m-t-20">
      <el-main class="main-body-table">
        <vxe-toolbar ref="toolbarRef" :refresh="{queryMethod: searchHandle}" export custom>
          <template #buttons>
            <vxe-input v-model="search.name" clearable type="search" placeholder="标签名称或者ID" @keyup="searchHandle"></vxe-input>
          </template>
        </vxe-toolbar>
        <div style="height: calc(100vh - 220px);">
        <vxe-table
          border
          show-overflow
          :data="tableData"
          v-loading="loading"
          :export-config="{}"
          align="center"
          ref="tableRef"
          height="auto"
          :row-config="{isHover: true}"
          :sort-config="{trigger: 'cell'}"
          :scroll-y="{enabled: true}"
        >
          <vxe-column type="seq" width="60px"></vxe-column>
          <vxe-column field="id" title="标签ID"  min-width="120px"></vxe-column>
          <vxe-column field="name" title="标签名称"  min-width="120px"></vxe-column>
          <vxe-column field="queryTime" title="查询时间"  min-width="120px" ></vxe-column>
          <vxe-column field="queryParams" title="查询参数" min-width="300px"></vxe-column>
          <vxe-column title="操作" width="100" fixed="right"  >
            <template #default="{ row }">
              <vxe-button type="text" status="primary" @click="openDetailHandle(row)" icon="vxe-icon-menu"></vxe-button>
              <vxe-button type="text" status="primary" @click="removeRow(row)" icon="vxe-icon-delete"></vxe-button>
              </template>
          </vxe-column>
        </vxe-table>
      </div>
       <!--工具条-->
       <el-pagination
          size="mini"
          style="margin-top: 5px;"
          class="main-body-table-page"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="search.current"
          :page-size="search.size"
          :total="total"
        ></el-pagination>
      </el-main>
      <el-drawer
        style="text-align: left;"
        size="calc(100% - 220px)"
        v-if="currentRow && currentRow.id"
        :visible.sync="openDetail"
        direction="rtl">
        <template #title>
          <div>
           <div style="font-weight: 700;color: #333;font-size: 16px;">{{ currentRow.name }}</div> 
            条件：{{ currentRow.queryParams }}
          </div>
        </template>
      <ListPage :label-id="currentRow.id"></ListPage>
      </el-drawer>
    </div>
  </template>
      
  <script>
  import ListPage from './list.vue'
  import {
    SelectPage,
    DelLabel
  } from '@/api/label'
  export default {
    data() {
      return {
        search: {
          current: 1,
          size: 10,
          name: ''
        },
        openDetail: false,
        total: 0,
        loading: false,
        form: {
          id: ''
        },
        currentRow: null,
        tableData: []
      }
    },
    components: {ListPage},
    methods: {
      resetForm () {
        this.$refs.searchForm.resetFields();
        this.$nextTick(() => {
          this.searchHandle()
        })
      },
      filterParams (queryParams) {
          let fileds = {
            boards: '板块',
            volumeRatio: '量比',
            turnoverRate: '换手率',
            empNum: '企业人数',
            orgParticipate: '主力参与度',
            dynamicPe: '市盈率',
            marketCapFloat: '流通市值',
            totalOperateIncome: '经营收入',
            listingDate: '上市时间',
            pledgeRatio: '质押率'
          }
          let data = []
          for(let key in queryParams){
             if (key == 'turnoverRateType') {
                continue;
              }
            if (queryParams[key] && (queryParams[key]+'').length > 0) {
              if (key == 'boards') {
                data.push(fileds[key] + '选择排除' + queryParams[key][0] +'等' + queryParams[key].length+'个板块')
              } else if (key == 'listingDate') {
                data.push(fileds[key] + ' 早于 ' + queryParams[key])
              } else {
                console.log(key, key.replace('Min', ''), key.replace('Max', ''))
                if (['volumeRatio', 'turnoverRate', 'empNum', 'pledgeRatio', 'dynamicPe', 'marketCapFloat', 'totalOperateIncome', 'orgParticipate'].includes(key.replace('Min', ''))) {
                  let type = fileds[key.replace('Min', '')]
                  if ('换手率' == type && queryParams.turnoverRateType) {
                    type = queryParams.turnoverRateType
                  }
                  data.push(type + ' > ' + queryParams[key])
                } else {
                  let type = fileds[key.replace('Max', '')]
                  if ('换手率' == type && queryParams.turnoverRateType) {
                    type = queryParams.turnoverRateType
                  }
                  data.push(type + ' < ' + queryParams[key])
                }
              } 
            }
          }
          return data.join(',')
        },
      openDetailHandle (row) {
        this.openDetail = true
        this.currentRow = row
      },
      searchHandle() {
        this.loadData()
      },
      handleSizeChange (val) {
        this.search.size = val
        this.loadData()
      },
      handleCurrentChange(val) {
        this.search.current = val
        this.loadData()
      },
      async removeRow (row) {
        this.$confirm(`确定删除标签吗?`, '提示', {
        type: 'warning'
      }).then( async () => {
        let index = this.tableData.findIndex(d => d.code == row.code)
        if (index != -1) {
          const $table = this.$refs.tableRef
          await $table.remove(row)
          this.tableData.splice(index, 1)
          DelLabel({labelId: row.id}).then(res => {
            if (res.code === 20000) {
              this.$message({
              type: 'success',
              message: '操作成功'
            })
            }
          })
        }
      })
    },
      loadData() {
        this.loading = true
        SelectPage(this.search)
          .then((res) => {
            console.log('SelectPage:', res)
            this.tableData = res.data.records.map(d => {
              d.queryParams = this.filterParams(JSON.parse(d.queryParams)) || '无条件'
              return d
            })
            this.total = res.data.total
            this.$nextTick(() => {
              this.loading = false
            })
          })
          .catch((err) => {
            console.log('err:', err)
          })
      }
    },
    async mounted() {
      this.$nextTick(() => {
        // 将表格和工具栏进行关联
        const $table = this.$refs.tableRef
        const $toolbar = this.$refs.toolbarRef
        if ($table && $toolbar) {
          $table.connect($toolbar)
        }
      })
      this.loadData()
    }
  }
  </script>
      
  <style lang="scss" scoped>
  .formlayout {
    padding: 20px 40px;
  }
  .el-icon-success {
    color: #409EFF;
    font-size: 20px;
  }
  ::v-deep {
    .el-table__body-wrapper {
      height: calc(100% - 40px) !important;
    }
    .el-input__inner {
      height: 34px;
    }
    .el-drawer__header {
      margin-bottom: 22px !important;
    }
  }
  </style>
      