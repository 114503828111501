import { post } from "../util/http"
/**
 * 分页
 * @param  params 
 */
export const SelectPage = params => post(`/stock/selectPage`, params)

/**
 * 修改
 * @param  params 
 */
export const UpdateInBlacklist = params => post(`/stock/updateInBlacklist`, params)



