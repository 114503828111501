  <!-- 会员用户 -->
  <template>
    <div class="m-t-20">
      <el-main class="main-body-table">
        <vxe-toolbar ref="toolbarRef" :refresh="{queryMethod: searchHandle}" export custom>
          <template #buttons>
            <vxe-input v-model="search.keyword" clearable type="search" placeholder="股票代码或者名称" @keyup="searchHandle"></vxe-input>
            <vxe-select style="margin-left: 10px;" v-model="search.inMachinePool" @change="searchHandle" clearable placeholder="选择是否在机选池">
              <vxe-option :value="1" label="是"></vxe-option>
              <vxe-option :value="0" label="否"></vxe-option>
            </vxe-select>
            <vxe-select style="margin-left: 10px;" v-model="search.inBlacklist" @change="searchHandle" clearable placeholder="过滤黑名单">
              <vxe-option value="" label="全部"></vxe-option>
              <vxe-option :value="0" label="否"></vxe-option>
              <vxe-option :value="1" label="是"></vxe-option>
            </vxe-select>
          </template>
        </vxe-toolbar>
        <div style="height: calc(100vh - 220px);">
        <vxe-table
          border
          show-overflow
          :data="tableData"
          v-loading="loading"
          :export-config="{}"
          align="center"
          ref="tableRef"
          height="auto"
          :row-config="{isHover: true}"
          :sort-config="{trigger: 'cell'}"
          :scroll-y="{enabled: true}"
        >
          <vxe-column type="seq"  fixed="left"></vxe-column>
          <vxe-column field="name" title="名称" fixed="left"  width="120px"></vxe-column>
          <vxe-column field="code" title="编码" fixed="left"  width="80px" ></vxe-column>
          <vxe-column field="inMachinePool" title="在机选" fixed="left" width="80px" >
            <template #default="{ row }">
              <i class="el-icon-success" v-if="row.inMachinePool == 1 "></i>
              <span v-else>-</span>
              </template>
          </vxe-column>
          <vxe-column field="inBlacklist" title="黑名单" width="80px" >
            <template #default="{ row }">
              <el-switch
                size="mini"
                :active-value="1"
                :inactive-value="0"
                v-model="row.inBlacklist"
                @change="changeBlacklist(row)"
                active-color="#ff0000">
              </el-switch>
              </template>
          </vxe-column>
          <vxe-column field="volumeRatio" title="量比" width="80px"></vxe-column>
          <vxe-column field="turnoverRate" title="换手率" width="100px"></vxe-column>
          <vxe-column field="empNum" title="企业人数" width="100px"></vxe-column>
          <vxe-column field="totalOperateIncome" title="企业收入(亿)" width="130px"></vxe-column>
          <vxe-column field="listingDate" title="上市时间" width="120px"></vxe-column>
          <vxe-column field="pledgeRatio" title="企业质押率" width="110px"></vxe-column>
          <vxe-column field="close" title="最新价格" width="100px"></vxe-column>
          <vxe-column field="tradeDate" title="交易日期"  width="120px"></vxe-column>
          <vxe-column field="changePercentage" title="涨跌幅" width="100px"></vxe-column>
          <vxe-column field="compreScore" title="综合评分" width="100px"></vxe-column>
          <vxe-column field="industryRank" title="行业排名"  width="100px"></vxe-column>
          <vxe-column field="boardName" title="行业"  width="80px"></vxe-column>
          <vxe-column field="dynamicPe" title="市盈率"  width="90px"></vxe-column>
          <vxe-column field="marketCapTotal" title="总市值(亿)"  width="120px"></vxe-column>
          <vxe-column field="marketCapFloat" title="流通市值(亿)"  width="130px"></vxe-column>
          <vxe-column field="averageIncrease1" title="涨跌预测"  width="100px"></vxe-column>
          <vxe-column field="orgParticipate" title="主力参与度"  width="110px"></vxe-column>
          <!-- <vxe-column title="操作" width="60" fixed="right"  >
            <template #default="{ row }">
              <vxe-button type="text" status="primary" @click="removeRow(row)" icon="vxe-icon-delete"></vxe-button>
              </template>
          </vxe-column> -->
        </vxe-table>
      </div>
       <!--工具条-->
       <el-pagination
          size="mini"
          style="margin-top: 5px;"
          class="main-body-table-page"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="search.current"
          :page-size="search.size"
          :total="total"
        ></el-pagination>
      </el-main>
    </div>
  </template>
      
      <script>
  import {
    SelectPage
  } from '@/api/selection'
  import {
    UpdateInBlacklist
  } from '@/api/stock'
  import moment from 'moment';
  export default {
    data() {
      return {
        search: {
          current: 1,
          size: 10,
          keyword: '',
          inMachinePool: ''
        },
        total: 0,
        loading: false,
        form: {
          id: ''
        },
        tableData: []
      }
    },
    methods: {
      resetForm () {
        this.$refs.searchForm.resetFields();
        this.$nextTick(() => {
          this.searchHandle()
        })
      },
      changeBlacklist(row) {
        UpdateInBlacklist({ code: row.code, inBlacklist: row.inBlacklist }).then((res) => {
          console.log('UpdateInBlacklist', res)
        })
      },
      searchHandle() {
        this.loadData()
      },
      handleSizeChange (val) {
        this.search.size = val
        this.loadData()
      },
      handleCurrentChange(val) {
        this.search.current = val
        this.loadData()
      },
      numberToBillion(num) {
        const billion = 100000000;
        return (num / billion).toFixed(2) * 1; // 转换为亿并保留两位小数
      },
      loadData() {
        this.loading = true
        SelectPage(this.search)
          .then((res) => {
            console.log('SelectPage:', res)
            this.tableData = res.data.records.map(d=> {
              if (d.listingDate) {
                d.listingDate = moment(d.listingDate).format('YYYY-MM-DD')
              }
              if (d.tradeDate) {
                d.tradeDate = moment(d.tradeDate).format('YYYY-MM-DD')
              }
              if (d.orgParticipate) {
                d.orgParticipate = (d.orgParticipate * 100).toFixed(2)
              }
              if (d.compreScore) {
                d.compreScore = d.compreScore.toFixed(2)
              }
              if (d.totalOperateIncome) {
                d.totalOperateIncome = this.numberToBillion(d.totalOperateIncome)
              }
              if (d.marketCapTotal) {
                d.marketCapTotal = this.numberToBillion(d.marketCapTotal)
              }
              if (d.marketCapFloat) {
                d.marketCapFloat = this.numberToBillion(d.marketCapFloat)
              }
              return d
            })
            this.total = res.data.total
            this.$nextTick(() => {
              this.loading = false
            })
          })
          .catch((err) => {
            console.log('err:', err)
          })
      }
    },
    async mounted() {
      this.$nextTick(() => {
        // 将表格和工具栏进行关联
        const $table = this.$refs.tableRef
        const $toolbar = this.$refs.toolbarRef
        if ($table && $toolbar) {
          $table.connect($toolbar)
        }
      })
      this.loadData()
    }
  }
  </script>
      
  <style lang="scss" scoped>
  .formlayout {
    padding: 20px 40px;
  }
  .el-icon-success {
    color: #409EFF;
    font-size: 20px;
  }
  ::v-deep {
    .el-table__body-wrapper {
      height: calc(100% - 40px) !important;
    }
    .el-input__inner {
      height: 34px;
    }
  }
  </style>
      