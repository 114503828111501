<template>
  <div style="text-align: left;height: calc(100vh - 120px)">
    <vxe-table
        border
        show-overflow
        :data="tableData"
        v-loading="loading"
        :export-config="{}"
        align="center"
        ref="tableRef"
        height="auto"
        :row-config="{isHover: true}"
        :sort-config="{trigger: 'cell'}"
        :scroll-y="{enabled: true}"
      >
        <vxe-column type="seq"  fixed="left" min-width="80px"></vxe-column>
        <vxe-column field="name" title="名称" fixed="left"  width="120px"></vxe-column>
        <vxe-column field="code" title="编码" fixed="left"   width="80px" ></vxe-column>
        <vxe-column field="volumeRatio" title="量比" sortable width="80px"></vxe-column>
        <vxe-column field="turnoverRate" title="换手率" sortable width="100px"></vxe-column>
        <vxe-column field="turnoverRate3" title="3日换手率" sortable width="120px"></vxe-column>
        <vxe-column field="turnoverRate30" title="30日换手率" sortable width="120px"></vxe-column>
        <vxe-column field="empNum" title="企业人数" sortable width="100px"></vxe-column>
        <vxe-column field="totalOperateIncome" title="企业收入(亿)" sortable width="130px"></vxe-column>
        <vxe-column field="listingDate" title="上市时间" sortable width="120px"></vxe-column>
        <vxe-column field="pledgeRatio" title="企业质押率" sortable width="110px"></vxe-column>
        <vxe-column field="close" title="最新价格" sortable width="100px"></vxe-column>
        <vxe-column field="tradeDate" title="交易日期"  width="120px"></vxe-column>
        <vxe-column field="changePercentage" title="涨跌幅" sortable width="100px"></vxe-column>
        <vxe-column field="compreScore" title="综合评分" sortable width="100px"></vxe-column>
        <vxe-column field="industryRank" title="行业排名" sortable width="100px"></vxe-column>
        <vxe-column field="boardName" title="行业"  width="80px"></vxe-column>
        <vxe-column field="dynamicPe" title="市盈率" sortable width="90px"></vxe-column>
        <vxe-column field="marketCapTotal" title="总市值(亿)" sortable width="120px"></vxe-column>
        <vxe-column field="marketCapFloat" title="流通市值(亿)" sortable width="130px"></vxe-column>
        <vxe-column field="averageIncrease1" title="涨跌预测" sortable width="100px"></vxe-column>
        <vxe-column field="orgParticipate" title="主力参与度" sortable width="110px"></vxe-column>
      </vxe-table>
      <div style="margin: 5px 15px;">总共{{ tableData.length }}条记录</div>
  </div>
</template>

<script>
  import {
    GetLabel
  } from '@/api/label'
export default {
  props: {
    labelId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      tableData: []
    }
  },
  watch: {
    labelId: {
      handler () {
        this.tableData = []
        if (this.labelId) {
          this.loadData()
        }
      },
      immediate: true
    }
  },
  methods: {
    loadData () {
      this.loading = true
      GetLabel({labelId: this.labelId}).then(res => {
        if (res.code === 20000) {
          this.tableData = JSON.parse(res.data.queryResult)
          this.$nextTick(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style>

</style>