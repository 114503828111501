  <!-- 会员用户 -->
  <template>
  <div class="m-t-20">
    <el-main class="main-body-table">
      <vxe-toolbar ref="toolbarRef" :refresh="{queryMethod: searchHandle}" export custom>
        <template #buttons>
          <el-popover placement="right" title="" width="900" trigger="click" v-model="showPopover">
            <vxe-button slot="reference" t type="text" icon="vxe-icon-funnel" class="tool-btn" :class="{'changed': !!filterParams}"></vxe-button>
            <el-form :model="search" @submit.native.prevent size="small" label-position="top" ref="searchForm">
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="板块(排除)" class="nja-form-line" prop="boards">
                    <el-cascader size="mini" style="width: 100%;" collapse-tags v-model="search.boards" :props="props" :options="options" filterable :show-all-levels="false"></el-cascader>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="企业质押率" class="nja-form-line" prop="pledgeRatio">
                    <NumberRange :startValue.sync="search.pledgeRatioMin" :endValue.sync="search.pledgeRatioMax"></NumberRange>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="企业收入（元）" class="nja-form-line" prop="totalOperateIncome">
                    <NumberRange :startValue.sync="search.totalOperateIncomeMin" :endValue.sync="search.totalOperateIncomeMax"></NumberRange>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="量比" class="nja-form-line" prop="volumeRatio">
                    <NumberRange :startValue.sync="search.volumeRatioMin" :endValue.sync="search.volumeRatioMax"></NumberRange>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="" class="nja-form-line" prop="turnoverRate">
                    <NumberRange :startValue.sync="search.turnoverRateMin" :endValue.sync="search.turnoverRateMax"></NumberRange>
                    <template #label>
                      <i class="el-icon-caret-bottom" style="color:blue"></i>
                      <el-select class="labelsele" v-model="search.turnoverRateType">
                        <el-option value="换手率" label="换手率"></el-option>
                        <el-option value="3日换手率" label="3日换手率"></el-option>
                        <el-option value="30日换手率" label="30日换手率"></el-option>
                      </el-select>
                    </template>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="企业人数（人）" class="nja-form-line" prop="empNum">
                    <NumberRange :startValue.sync="search.empNumMin" :endValue.sync="search.empNumMax"></NumberRange>
                  </el-form-item>
                </el-col>
               
                <el-col :span="6">
                  <el-form-item label="上市时间（早于）" class="nja-form-line" prop="listingDate">
                    <el-date-picker
                      v-model="search.listingDate"
                      type="date"
                      clearable
                      style="width: 100%"
                      value-format="yyyy-MM-dd"
                      placeholder="选择日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="流通市值（元）" class="nja-form-line" prop="marketCapFloat">
                    <NumberRange :startValue.sync="search.marketCapFloatMin" :endValue.sync="search.marketCapFloatMax"></NumberRange>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="市盈率" class="nja-form-line" prop="dynamicPe">
                    <NumberRange :startValue.sync="search.dynamicPeMin" :endValue.sync="search.dynamicPeMax"></NumberRange>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="主力参与度" class="nja-form-line" prop="orgParticipate">
                    <NumberRange :startValue.sync="search.orgParticipateMin" :endValue.sync="search.orgParticipateMax"></NumberRange>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item>
                    <el-button type="primary" @click="searchHandle" icon="el-icon-search">查 询</el-button>
                    <el-button type="warning" @click="resetForm" icon="el-icon-search">重 置</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-popover>
          <div>
            选择条件：{{ filterParams|| '无条件' }}
          </div>
        </template>
      </vxe-toolbar>
      <div style="height: calc(100vh - 230px);">
      <vxe-table
        border
        show-overflow
        :data="tableData"
        v-loading="loading"
        :export-config="{}"
        align="center"
        ref="tableRef"
        height="auto"
        :row-config="{isHover: true}"
        :sort-config="{trigger: 'cell'}"
        :scroll-y="{enabled: true}"
      >
        <vxe-column type="seq"  fixed="left" min-width="80px"></vxe-column>
        <vxe-column field="name" title="名称" fixed="left"  width="120px"></vxe-column>
        <vxe-column field="code" title="编码" fixed="left"   width="80px" ></vxe-column>
        <vxe-column field="volumeRatio" title="量比" sortable width="80px"></vxe-column>
        <vxe-column field="turnoverRate" title="换手率" sortable width="100px"></vxe-column>
        <vxe-column field="turnoverRate3" title="3日换手率" sortable width="120px"></vxe-column>
        <vxe-column field="turnoverRate30" title="30日换手率" sortable width="120px"></vxe-column>
        <vxe-column field="empNum" title="企业人数" sortable width="100px"></vxe-column>
        <vxe-column field="totalOperateIncome" title="企业收入(亿)" sortable width="130px"></vxe-column>
        <vxe-column field="listingDate" title="上市时间" sortable width="120px"></vxe-column>
        <vxe-column field="pledgeRatio" title="企业质押率" sortable width="110px"></vxe-column>
        <vxe-column field="close" title="最新价格" sortable width="100px"></vxe-column>
        <vxe-column field="tradeDate" title="交易日期"  width="120px"></vxe-column>
        <vxe-column field="changePercentage" title="涨跌幅" sortable width="100px"></vxe-column>
        <vxe-column field="compreScore" title="综合评分" sortable width="100px"></vxe-column>
        <vxe-column field="industryRank" title="行业排名" sortable width="100px"></vxe-column>
        <vxe-column field="boardName" title="行业"  width="80px"></vxe-column>
        <vxe-column field="dynamicPe" title="市盈率" sortable width="90px"></vxe-column>
        <vxe-column field="marketCapTotal" title="总市值(亿)" sortable width="120px"></vxe-column>
        <vxe-column field="marketCapFloat" title="流通市值(亿)" sortable width="130px"></vxe-column>
        <vxe-column field="averageIncrease1" title="涨跌预测" sortable width="100px"></vxe-column>
        <vxe-column field="orgParticipate" title="主力参与度" sortable width="110px"></vxe-column>
        <vxe-column title="操作" width="60" fixed="right"  >
          <template #default="{ row }">
            <vxe-button type="text" status="primary" @click="removeRow(row)" icon="vxe-icon-delete"></vxe-button>
            </template>
        </vxe-column>
      </vxe-table>
    </div>
    <div class="footer">
      <div>
        <el-button type="primary" :loading="isFetch" @click="addMachinePool" v-if="tableData.length >0" size="small" icon="el-icon-plus">添加至机选</el-button>
        <el-button type="primary" :loading="isFetch" @click="AddLabelHandle" v-if="tableData.length >0" size="small" icon="el-icon-plus">分类标签</el-button>
      </div>
      <div>总 {{ isFetch ? total : tableData.length }} 条数据</div>
    </div>
    </el-main>
  </div>
</template>
    
    <script>
import {
  SelectPage,
  SelectListBoard,
  AddMachinePool
} from '@/api/selection'
import {
    AddLabel
  } from '@/api/label'
import NumberRange from '@/components/NumberRange'
import moment from 'moment'
export default {
  components: {NumberRange},
  data() {
    return {
      showPopover: false,
      search: {
        boards: [],
        turnoverRateType: '换手率',
        volumeRatioMin: null,
        volumeRatioMax: null,
        turnoverRateMin: null,
        turnoverRateMax: null,
        empNumMin: null,
        empNumMax: null,
        dynamicPeMin: null,
        dynamicPeMax: null,
        marketCapFloatMin: null,
        marketCapFloatMax: null,
        totalOperateIncomeMin: null,
        totalOperateIncomeMax: null,
        listingDate: '',
        orgParticipateMin: null,
        orgParticipateMax: null,
        pledgeRatioMin: null,
        pledgeRatioMax: null
      },
      options: [],
      props: {
        multiple: true,
        emitPath: false,
        label: 'name',
        value: 'code'
      },
      loading: false,
      form: {
        id: '',
        userName: '',
        gender: '',
        age: '',
        phoneNumber: '',
        address: '',
        comment: ''
      },
      isFetch: false,
      total: 0,
      tableData: []
    }
  },
  computed: {
    filterParams () {
      let fileds = {
        boards: '板块',
        volumeRatio: '量比',
        turnoverRate: '换手率',
        empNum: '企业人数',
        orgParticipate: '主力参与度',
        dynamicPe: '市盈率',
        marketCapFloat: '流通市值',
        totalOperateIncome: '经营收入',
        listingDate: '上市时间',
        pledgeRatio: '质押率'
      }
      let data = []
      for(let key in this.search){
        if (key == 'turnoverRateType') {
          continue;
        }
        if (this.search[key] && (this.search[key]+'').length > 0) {
          if (key == 'boards') {
            data.push(fileds[key] + '选择排除' + this.search[key][0] +'等' + this.search[key].length+'个板块')
          } else if (key == 'listingDate') {
            data.push(fileds[key] + ' 早于 ' + this.search[key])
          } else {
            console.log(key, key.replace('Min', ''), key.replace('Max', ''))
            // 换手率
            if (['volumeRatio', 'turnoverRate', 'empNum', 'pledgeRatio', 'dynamicPe', 'marketCapFloat', 'totalOperateIncome', 'orgParticipate'].includes(key.replace('Min', ''))) {
              let type = fileds[key.replace('Min', '')]
              if ('换手率' == type && this.search.turnoverRateType) {
                type = this.search.turnoverRateType
              }
              data.push(type + ' > ' + this.search[key])
            } else {
              let type = fileds[key.replace('Max', '')]
              if ('换手率' == type && this.search.turnoverRateType) {
                type = this.search.turnoverRateType
              }
              data.push(type + ' < ' + this.search[key])
            }
          } 
        }
      }
      return data.join(',')
    }
  },
  methods: {
    resetForm () {
      this.$refs.searchForm.resetFields();
      this.search = {
        boards: [],
        turnoverRateType: '换手率',
        volumeRatioMin: null,
        volumeRatioMax: null,
        turnoverRateMin: null,
        turnoverRateMax: null,
        empNumMin: null,
        empNumMax: null,
        totalOperateIncomeMin: null,
        totalOperateIncomeMax: null,
        listingDate: '',
        pledgeRatioMin: null,
        pledgeRatioMax: null
      }
      this.$nextTick(() => {
        this.searchHandle()
      })
    },
    async removeRow (row) {
      let index = this.tableData.findIndex(d => d.code == row.code)
      if (index != -1) {
        const $table = this.$refs.tableRef
        await $table.remove(row)
        this.tableData.splice(index, 1)
      }
    },
    searchHandle() {
      localStorage.setItem('selection-search', JSON.stringify(this.search))
      this.showPopover = false
      this.loadData()
    },
    AddLabelHandle () {
      this.$prompt('请输入标签名称', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^.*[^\s]+.*$/,
          inputErrorMessage: '必填'
        }).then(({ value }) => {
          let queryParams =  JSON.stringify(this.search)
          let queryResult =  JSON.stringify(this.tableData)
          this.loading = true
          this.isFetch = true
          AddLabel({
            name: value,
            queryParams,
            queryResult
          }).then(res => {
            console.log('AddLabel', res)
            if (res.code === 20000) {
              this.loading = false
              this.isFetch = false
              this.$message({
                  type: 'success',
                  message: '操作成功'
                })
            }
          })
        })
    },
    addMachinePool () {
      this.$confirm(`确定添加至机选吗?`, '提示', {
        type: 'warning'
      }).then(() => {
        AddMachinePool(this.tableData.map(d => d.code)).then(res => {
          console.log('AddMachinePool', res)
          if (res.code === 20000) {
            this.$message({
                type: 'success',
                message: '操作成功'
              })
          }
        })
      })
    },
    loadBoards() {
      SelectListBoard().then(res => {
        console.log('SelectListBoard', res)
        let dyList = [],hyList = [],gnList = []
        res.data.forEach(d => {
          if (d.type == 'area') {
            dyList.push(d)
          } else if (d.type == 'industry') {
            hyList.push(d)
          } else {
            gnList.push(d)
          }
        })
        this.options = [
          {
            name: '地域',
            code: '',
            children: dyList
          }, {
            name: '行业',
            code: '',
            children: hyList
          }, {
            name: '概念',
            code: '',
            children: gnList
          }
        ]
      })
    },
    numberToBillion(num) {
      const billion = 100000000;
      return (num / billion).toFixed(2)  * 1; // 转换为亿并保留两位小数
    },
    loadPage(page = 1) {
      let params = Object.assign({current: page, size: 1000, inBlacklist: 0}, this.search)
      if (params.orgParticipateMin) {
        params.orgParticipateMin = params.orgParticipateMin / 100
      }
      if (params.orgParticipateMax) {
        params.orgParticipateMax = params.orgParticipateMax / 100
      }
      SelectPage(params)
        .then((res) => {
          console.log('SelectPage:', res)
          this.tableData.push(...res.data.records.map(d=> {
              if (d.listingDate) {
                d.listingDate = moment(d.listingDate).format('YYYY-MM-DD')
              }
              if (d.tradeDate) {
                d.tradeDate = moment(d.tradeDate).format('YYYY-MM-DD')
              }
              if (d.orgParticipate) {
                d.orgParticipate = (d.orgParticipate * 100).toFixed(2)
              }
              if (d.compreScore) {
                d.compreScore = d.compreScore.toFixed(2)
              }
              if (d.turnoverRate3) {
                d.turnoverRate3 = d.turnoverRate3.toFixed(2)
              }
              if (d.turnoverRate30) {
                d.turnoverRate30 = d.turnoverRate30.toFixed(2)
              }
              if (d.totalOperateIncome) {
                d.totalOperateIncome = this.numberToBillion(d.totalOperateIncome)
              }
              if (d.marketCapTotal) {
                d.marketCapTotal = this.numberToBillion(d.marketCapTotal)
              }
              if (d.marketCapFloat) {
                d.marketCapFloat = this.numberToBillion(d.marketCapFloat)
              }
              return d
          }))
          this.total = res.data.total
          if (page < res.data.pages) {
            if (this.isFetch) {
              this.loadPage(page+1)
            }
          } else {
            this.isFetch = false
          }
          this.$nextTick(() => {
            this.loading = false
          })
      });
    },
    loadData() {
      this.isFetch = true
      this.loading = true
      this.tableData=[]
      this.loadPage(1)
    }
  },
  beforeDestroy () {
    this.isFetch = false
  },
  async mounted() {
    this.$nextTick(() => {
      // 将表格和工具栏进行关联
      const $table = this.$refs.tableRef
      const $toolbar = this.$refs.toolbarRef
      if ($table && $toolbar) {
        $table.connect($toolbar)
      }
    })
    this.search = JSON.parse(localStorage.getItem('selection-search'))||{}
    if(!this.search.turnoverRateType) {
      this.search.turnoverRateType = '换手率'
    }
    this.loadBoards()
    this.loadData()
  }
}
</script>
    
<style lang="scss" scoped>
.formlayout {
  padding: 20px 40px;
}
.changed {
  color: $primary-color;
}
::v-deep {
  .el-table__body-wrapper {
    height: calc(100% - 40px) !important;
  }
  .el-input__inner {
    height: 34px;
  }
}
.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.labelsele {
  ::v-deep {
    .el-input {
      height: 20px;
      .el-input__inner {
        height: 20px;
        border: none;
        padding-left: 5px;
        color: blue;
      }
    }
    .el-input__suffix{
        display: none;
      }
  }
}
</style>
    