import { post, postJson } from "../util/http"
/**
 * 分页
 * @param  params 
 */
export const SelectPage = params => post(`/stock/query/label/selectPage`, params)

/**
 * 查询标签
 * @param  params 
 */
export const GetLabel = params => post(`/stock/query/label/get`, params)

/**
 * 添加标签
 * @param  params 
 */
export const AddLabel = params => postJson(`/stock/query/label/add`, params)

/**
 * 删除标签
 * @param  params 
 */
export const DelLabel = params => post(`/stock/query/label/del`, params)


